import { useState, useEffect } from 'react';

const getUserAgent = () => {
  try {
    return navigator.userAgent || navigator.vendor || window.opera;
  } catch (_error) {
    return '';
  }
};

const userAgent = getUserAgent();

export function getMobileOperatingSystem() {
  try {
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  } catch (_error) {
    return 'unknown';
  }
}

function getAndroidVersion() {
  const match = userAgent.match(/android\s([0-9.]*)/i);

  return match ? parseFloat(match[1]) : undefined;
}

function getiOSVersion() {
  const match = userAgent.match(/OS\s([\d_]+)/i);

  return match ? parseFloat(match[1].replace(/_/g, '.')) : undefined;
}

export function getMobileDetailInfo() {
  const os = getMobileOperatingSystem();

  let version;

  if (os === 'Android') {
    version = getAndroidVersion();
  } else if (os === 'iOS') {
    version = getiOSVersion();
  } else {
    version = undefined;
  }

  return {
    os,
    version,
  };
}

export default function useMobileSystem() {
  const [device, setDevice] = useState('unknown');

  useEffect(() => {
    setTimeout(() => {
      setDevice(getMobileOperatingSystem());
    }, 0);
  }, []);

  return device;
}
